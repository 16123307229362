var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "models-page" },
    [
      _c("section", [
        _c("div", { staticClass: "container py-5" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "md-down:col-12 lg:col-5 xl:col-3" },
              [
                _c("mega-card", { attrs: { title: _vm.getTitle } }, [
                  !(_vm.is_3d || _vm.is_mask)
                    ? _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c(
                            "mega-drop-zone",
                            {
                              attrs: {
                                type: _vm.is_gif
                                  ? ["image/gif"]
                                  : ["image/jpeg", "image/png"]
                              },
                              on: { change: _vm.renderFile }
                            },
                            [
                              !_vm.preview
                                ? _c("mega-image", {
                                    attrs: { ratio: "1x1", src: "" }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.preview
                                ? _c("img", {
                                    staticClass: "w-100",
                                    class: { "opacity-0": _vm.rendering },
                                    staticStyle: { transition: ".1s" },
                                    attrs: { alt: "", src: _vm.preview }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("mega-input", {
                        attrs: { label: _vm.$t("model_name") },
                        on: {
                          input: function($event) {
                            _vm.save_disabled = false
                          }
                        },
                        model: {
                          value: _vm.model.name,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "name", $$v)
                          },
                          expression: "model.name"
                        }
                      }),
                      _vm._v(" "),
                      !_vm.model.idc_round_type
                        ? _c("mega-select", {
                            staticClass: "mb-0",
                            attrs: {
                              label: _vm.$t("model_group"),
                              placeholder: _vm.$t("model_group_holder"),
                              options: []
                            },
                            on: {
                              select: function($event) {
                                _vm.save_disabled = false
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.model.idc_round_type &&
                      (_vm.is_3d || _vm.is_mask || _vm.is_gif)
                        ? _c("group-select", {
                            staticClass: "mb-0",
                            attrs: {
                              label: _vm.$t("model_group"),
                              placeholder: _vm.$t("model_group_holder"),
                              type: _vm.model.idc_round_type
                            },
                            on: {
                              select: function($event) {
                                _vm.save_disabled = false
                              }
                            },
                            model: {
                              value: _vm.model.idt_group,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "idt_group", $$v)
                              },
                              expression: "model.idt_group"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("ul", { staticClass: "menu-list" }, [
                    _c(
                      "li",
                      { staticClass: "menu-item" },
                      [
                        _c("mega-switch", {
                          staticClass: "w-100 mb-0 bg-success",
                          attrs: {
                            label: _vm.$t("model_show"),
                            checked: _vm.model["is_active"]
                          },
                          on: { click: _vm.toggleActive }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "menu-list" }, [
                    _c(
                      "li",
                      { staticClass: "menu-item d-flex flex-column" },
                      [
                        _c("mega-switch", {
                          staticClass: "w-100 mb-0 bg-success",
                          attrs: {
                            label: _vm.$t("model_make_exclusive"),
                            checked: _vm.model.exclusive
                          },
                          on: { click: _vm.toggleExclusive }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm.is_3d || _vm.is_mask || _vm.is_portal
                  ? _c(
                      "mega-card",
                      {
                        attrs: {
                          title: _vm.$t("model_modifications"),
                          menu: [
                            {
                              text: _vm.model.modifications
                                ? _vm.model.modifications.length
                                : 0
                            }
                          ]
                        }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "xs:mb-3 xs:col-12 sm-up:col-9 md:col-10 lg-up:col-8"
                              },
                              [
                                _c("mega-select", {
                                  attrs: { options: _vm.modif_list },
                                  on: { select: _vm.openModif },
                                  model: {
                                    value: _vm.current_modif,
                                    callback: function($$v) {
                                      _vm.current_modif = $$v
                                    },
                                    expression: "current_modif"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col sm-up:pl-0" },
                              [
                                _c(
                                  "mega-button",
                                  {
                                    staticClass: "btn w-100 btn-primary p-0",
                                    attrs: { loading: _vm.loading },
                                    on: { click: _vm.addModification }
                                  },
                                  [_c("i", { staticClass: "i-plus" })]
                                )
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-body pt-0" },
                          [
                            _c(
                              "mega-drop-zone",
                              {
                                attrs: {
                                  type: ["image/jpeg", "image/png", "image/gif"]
                                },
                                on: { change: _vm.renderFile }
                              },
                              [
                                !_vm.preview
                                  ? _c("mega-image", {
                                      attrs: { ratio: "1x1", src: "" }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.preview
                                  ? _c("img", {
                                      staticClass: "w-100",
                                      class: { "opacity-0": _vm.rendering },
                                      staticStyle: { transition: ".1s" },
                                      attrs: { alt: "", src: _vm.preview }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("mega-switch", {
                              staticClass: "w-100 bg-success",
                              attrs: {
                                label: _vm.$t("model_mod_show"),
                                disabled: !(
                                  _vm.mask["ios"] ||
                                  _vm.mask["android"] ||
                                  _vm.mask["web"] ||
                                  (_vm.modification["url_sfb"] &&
                                    _vm.modification["url_archive"]) ||
                                  _vm.modification.mask.ios_legacy.zip ||
                                  _vm.modification.mask.ios_legacy.texture
                                ),
                                checked: _vm.modification["is_active"]
                              },
                              on: {
                                click: function($event) {
                                  return _vm.changeModif(
                                    "is_active",
                                    !_vm.modification["is_active"]
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.is_3d
                              ? _c("mega-switch", {
                                  staticClass: "w-100 bg-success",
                                  attrs: {
                                    label: "1:1",
                                    disabled: !(
                                      _vm.modification["url_sfb"] ||
                                      _vm.modification["url_archive"]
                                    ),
                                    checked:
                                      _vm.modification["three_d"]["scaled"]
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeModif(
                                        "scaled",
                                        !_vm.modification["three_d"]["scaled"]
                                      )
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("mega-input", {
                              attrs: { label: _vm.$t("model_mod_name") },
                              on: {
                                input: function($event) {
                                  _vm.save_disabled = false
                                }
                              },
                              model: {
                                value: _vm.modification.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.modification, "name", $$v)
                                },
                                expression: "modification.name"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("mega-card", [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c(
                        "mega-button",
                        {
                          staticClass: "btn w-100 btn-success",
                          attrs: {
                            loading: _vm.loading,
                            disabled: _vm.save_disabled
                          },
                          on: { click: _vm.submit }
                        },
                        [_vm._v(_vm._s(_vm.$t("model_save")))]
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "no-ssr",
                  [_c("qr-code", { attrs: { uri: _vm.qrParams } })],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "md-down:col-12 lg:col-7 xl:col-9" },
              [
                _vm.loaded && !_vm.modification["url_sfb"] && _vm.is_3d
                  ? _c(
                      "mega-card",
                      { staticClass: "mb-0" },
                      [
                        _c(
                          "mega-alert",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              title: _vm.$t("model_warn"),
                              type: "warning"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("model_android_warn")))]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loaded && !_vm.modification["url_archive"] && _vm.is_3d
                  ? _c(
                      "mega-card",
                      { staticClass: "mb-0" },
                      [
                        _c(
                          "mega-alert",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              title: _vm.$t("model_warn"),
                              type: "warning"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("model_ios_warn")))]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loaded &&
                !(_vm.mask["android"] && _vm.mask["android"]["sfb"]) &&
                _vm.is_mask
                  ? _c(
                      "mega-card",
                      { staticClass: "mb-0" },
                      [
                        _c(
                          "mega-alert",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              title: _vm.$t("model_warn"),
                              type: "warning"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("model_android_sfb_mask_warn"))
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loaded &&
                !(_vm.mask["android"] && _vm.mask["android"]["texture"]) &&
                _vm.is_mask
                  ? _c(
                      "mega-card",
                      { staticClass: "mb-0" },
                      [
                        _c(
                          "mega-alert",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              title: _vm.$t("model_warn"),
                              type: "warning"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("model_android_texture_mask_warn"))
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loaded &&
                !(_vm.mask["ios"] && _vm.mask["ios"]["zip"]) &&
                _vm.is_mask
                  ? _c(
                      "mega-card",
                      { staticClass: "mb-0" },
                      [
                        _c(
                          "mega-alert",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              title: _vm.$t("model_warn"),
                              type: "warning"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("model_ios_mask_warn")))]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loaded &&
                !(_vm.mask["ios"] && _vm.mask["ios"]["texture"]) &&
                _vm.is_mask
                  ? _c(
                      "mega-card",
                      { staticClass: "mb-0" },
                      [
                        _c(
                          "mega-alert",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              title: _vm.$t("model_warn"),
                              type: "warning"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("model_ios_tex_warn")))]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loaded && !_vm.mask["web"] && _vm.is_mask
                  ? _c(
                      "mega-card",
                      { staticClass: "mb-0" },
                      [
                        _c(
                          "mega-alert",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              title: _vm.$t("model_warn"),
                              type: "warning"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("model_web_mask_warn")))]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("mega-card", { attrs: { title: _vm.$t("model_vis") } }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("ul", { staticClass: "menu-list" }, [
                      _c(
                        "li",
                        { staticClass: "menu-item" },
                        [
                          _c("mega-switch", {
                            staticClass: "w-100 mb-0 bg-success",
                            attrs: {
                              label: _vm.$t("model_vis_ios"),
                              disabled: _vm.is_visible_ios,
                              checked: _vm.modification["is_ios"]
                            },
                            on: {
                              click: function($event) {
                                return _vm.changeModif(
                                  "is_ios",
                                  !_vm.modification["is_ios"]
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "menu-item" },
                        [
                          _c("mega-switch", {
                            staticClass: "w-100 mb-0 bg-success",
                            attrs: {
                              label: _vm.$t("model_vis_android"),
                              disabled: _vm.is_visible_android,
                              checked: _vm.modification["is_android"]
                            },
                            on: {
                              click: function($event) {
                                return _vm.changeModif(
                                  "is_android",
                                  !_vm.modification["is_android"]
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.is_portal
                        ? _c(
                            "li",
                            { staticClass: "menu-item" },
                            [
                              _c("mega-switch", {
                                staticClass: "w-100 mb-0 bg-success",
                                attrs: {
                                  label: _vm.$t("model_vis_web"),
                                  disabled: _vm.is_visible_web,
                                  checked: _vm.modification["is_web"]
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.changeModif(
                                      "is_web",
                                      !_vm.modification["is_web"]
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                ]),
                _vm._v(" "),
                _vm.is_mask || _vm.is_3d
                  ? _c(
                      "mega-card",
                      { attrs: { title: _vm.$t("model_archives") } },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c(
                            "ul",
                            { staticClass: "menu-list" },
                            [
                              _vm.is_3d
                                ? _c("model-file", {
                                    attrs: {
                                      type: "apple_3d",
                                      placeholder: "iOS",
                                      modif: _vm.modification
                                    },
                                    on: { drop: _vm.getModel }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.is_3d
                                ? _c("model-file", {
                                    attrs: {
                                      type: "android_3d",
                                      placeholder: "Android",
                                      modif: _vm.modification
                                    },
                                    on: { drop: _vm.getModel }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.is_3d
                                ? _c("model-file", {
                                    attrs: {
                                      type: "web_3d",
                                      placeholder: "Web",
                                      modif: _vm.modification
                                    },
                                    on: { drop: _vm.getModel }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.is_3d
                                ? _c("model-file", {
                                    attrs: {
                                      type: "usdz_web_3d",
                                      placeholder: "Web USDZ, iOS",
                                      modif: _vm.modification
                                    },
                                    on: { drop: _vm.getModel }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.is_mask
                                ? _c("model-file", {
                                    attrs: {
                                      type: "apple_mask_zip",
                                      placeholder: "iOS Zip",
                                      modif: _vm.modification,
                                      mask: _vm.mask
                                    },
                                    on: { drop: _vm.getModel }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.is_mask
                                ? _c("model-file", {
                                    attrs: {
                                      type: "apple_mask_tex",
                                      placeholder: "iOS iPhone 10+ texture",
                                      modif: _vm.modification,
                                      mask: _vm.mask
                                    },
                                    on: { drop: _vm.getModel }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.is_mask
                                ? _c("model-file", {
                                    attrs: {
                                      type: "ios_mask_legacy",
                                      placeholder: "iOS legacy mask",
                                      modif: _vm.modification,
                                      mask: _vm.mask
                                    },
                                    on: { drop: _vm.getModel }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.is_mask
                                ? _c("model-file", {
                                    attrs: {
                                      type: "ios_mask_legacy_tex",
                                      placeholder: "iOS iPhone 7, 8 texture",
                                      modif: _vm.modification,
                                      mask: _vm.mask
                                    },
                                    on: { drop: _vm.getModel }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.is_mask
                                ? _c("model-file", {
                                    attrs: {
                                      type: "android_sfb_mask",
                                      placeholder: "Android SFB",
                                      modif: _vm.modification,
                                      mask: _vm.mask
                                    },
                                    on: { drop: _vm.getModel }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.is_mask
                                ? _c("model-file", {
                                    attrs: {
                                      type: "android_tex_mask",
                                      placeholder: "Android Texture",
                                      modif: _vm.modification,
                                      mask: _vm.mask
                                    },
                                    on: { drop: _vm.getModel }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.is_mask
                                ? _c("model-file", {
                                    attrs: {
                                      type: "web_mask",
                                      placeholder: "Web",
                                      modif: _vm.modification,
                                      mask: _vm.mask
                                    },
                                    on: { drop: _vm.getModel }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_portal
                  ? _c(
                      "mega-card",
                      { attrs: { title: _vm.$t("model_archives") } },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c(
                            "ul",
                            { staticClass: "menu-list" },
                            [
                              _c("model-file", {
                                attrs: {
                                  type: "portal_ios_zip",
                                  placeholder: "iOS portal zip",
                                  modif: _vm.modification,
                                  mask: _vm.mask
                                },
                                on: { drop: _vm.getModel }
                              }),
                              _vm._v(" "),
                              _c("model-file", {
                                attrs: {
                                  type: "portal_android_sfb",
                                  placeholder: "Android SFB portal",
                                  modif: _vm.modification,
                                  mask: _vm.mask
                                },
                                on: { drop: _vm.getModel }
                              }),
                              _vm._v(" "),
                              _c("model-file", {
                                attrs: {
                                  type: "portal_video",
                                  placeholder: "Portal video",
                                  modif: _vm.modification,
                                  mask: _vm.mask
                                },
                                on: { drop: _vm.getModel }
                              }),
                              _vm._v(" "),
                              _c("model-file", {
                                attrs: {
                                  type: "portal_photo",
                                  placeholder: "Portal photo",
                                  modif: _vm.modification,
                                  mask: _vm.mask
                                },
                                on: { drop: _vm.getModel }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_3d
                  ? _c(
                      "mega-card",
                      { attrs: { title: _vm.$t("model_audio") } },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c(
                            "ul",
                            { staticClass: "menu-list" },
                            [
                              _c("model-file", {
                                attrs: {
                                  type: "music",
                                  accept: ".mp3",
                                  placeholder: _vm.$t("model_audio_file"),
                                  modif: _vm.modification
                                },
                                on: { drop: _vm.getModel }
                              }),
                              _vm._v(" "),
                              _vm.modification["audio"]
                                ? _c(
                                    "li",
                                    {
                                      staticClass: "menu-item md-down:d-block"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "form-label mr-auto md-down:mb-3"
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "mr-3",
                                            class:
                                              _vm.playModes[_vm.play_mode].icon
                                          }),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t("audio_mode")) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-down:d-flex md-down:w-100"
                                        },
                                        _vm._l(_vm.playModes, function(
                                          mode,
                                          i
                                        ) {
                                          return _c(
                                            "mega-button",
                                            {
                                              key: i,
                                              staticClass:
                                                "btn-sm md-down:p-0 md-down:w-100",
                                              class: {
                                                "btn-primary":
                                                  _vm.play_mode === i
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.changePlayMode(i)
                                                }
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(mode.name))
                                              ])
                                            ]
                                          )
                                        }),
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_gif
                  ? _c("mega-card", { attrs: { title: _vm.$t("gif_src") } }, [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("mega-image", {
                            staticStyle: {
                              "background-size": "contain",
                              "background-repeat": "no-repeat"
                            },
                            attrs: { ratio: "16x9", src: _vm.modification.url }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_video
                  ? _c("mega-card", { attrs: { title: _vm.$t("video_src") } }, [
                      _vm.modification.video
                        ? _c(
                            "div",
                            { staticClass: "card-body d-flex flex-column" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c(
                                    "mega-btn-upload",
                                    {
                                      staticClass:
                                        "btn w-100 mb-3 btn-primary align-self-end",
                                      attrs: {
                                        loading: _vm.loading,
                                        type: ["video/mp4"]
                                      },
                                      on: { change: _vm.loadVideo }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("video_new")))]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("mega-video", {
                                attrs: {
                                  ratio: "16x9",
                                  src: _vm.modification.video
                                    ? _vm.modification.video.url
                                    : ""
                                }
                              })
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "card-body" },
                            [
                              _c(
                                "mega-drop-zone",
                                {
                                  staticClass: "content-center mt-3 p-5",
                                  attrs: { type: ["video/mp4"] },
                                  on: { change: _vm.loadVideo }
                                },
                                [
                                  _c("mega-video", {
                                    attrs: {
                                      ratio: "16x9",
                                      src: _vm.modification.video
                                        ? _vm.modification.video.url
                                        : ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "mega-card",
                  {
                    attrs: {
                      title: _vm.$t("model_rec_imgs"),
                      menu: [
                        {
                          icon: "i-plus",
                          click: _vm.addImage,
                          style: "font-size:24px"
                        }
                      ]
                    }
                  },
                  [
                    _vm.serverRefImages.length
                      ? _c("div", { staticClass: "card-body" }, [
                          _c(
                            "ul",
                            { staticClass: "menu-list" },
                            _vm._l(_vm.serverRefImages, function(img, i) {
                              return _c(
                                "li",
                                {
                                  key: i,
                                  staticClass:
                                    "d-flex align-items-center justify-content-between p-3"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-column",
                                      style: {
                                        fontSize: "15px",
                                        paddingRight: "10px"
                                      }
                                    },
                                    [
                                      _c("span", {}, [
                                        _vm._v("ID: "),
                                        _c("b", [
                                          _vm._v(_vm._s(img.idt_ref_image))
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "mr-auto" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("ref_info_name")) + ": "
                                        ),
                                        _c("b", [_vm._v(_vm._s(img.name))])
                                      ]),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "mr-auto" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("ref_info_date")) +
                                            ":\n                      "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.refDate(img.a_time))
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "mr-auto" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("ref_pic_width")) + ": "
                                        ),
                                        _c("b", [_vm._v(_vm._s(img.width))])
                                      ]),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "mr-auto" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("ref_global_loc")) +
                                            ":\n                      "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              img.is_global
                                                ? _vm.$t("ref_info_on")
                                                : _vm.$t("ref_info_off")
                                            )
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "mr-auto" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("ref_sticky")) +
                                            ":\n                      "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              img.sticky
                                                ? _vm.$t("ref_info_on")
                                                : _vm.$t("ref_info_off")
                                            )
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      img.ref_strings
                                        ? _c(
                                            "span",
                                            { staticClass: "mr-auto" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("ref_info_key_words")
                                                ) + ":\n                      "
                                              ),
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    img.ref_strings.join(",")
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      img.ocr_strings
                                        ? _c("Dropdown", {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              label: _vm.$t("ref_ocr"),
                                              key_words: img.ocr_strings
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "140px",
                                        flex: "0 0 140px"
                                      }
                                    },
                                    [
                                      _c("mega-image", {
                                        staticClass: "cursor-pointer",
                                        attrs: {
                                          ratio: "1x1",
                                          small: "",
                                          src: img.url
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openPreview(img.url)
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "mega-button",
                                        {
                                          staticClass:
                                            "btn w-100 btn-primary p-0 mt-2",
                                          on: {
                                            click: function($event) {
                                              return _vm.editImage(
                                                img["idt_ref_image"]
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("ref_edit_btn")))]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("chart", {
                  attrs: {
                    endpoint: "/stat/share/model",
                    idt_model: _vm.model_id,
                    chartName: "shares",
                    doubleGraph: false
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("new-image", {
        ref: "new-image",
        attrs: { id: _vm.model_id, mod_id: _vm.modification_id },
        on: { close: _vm.getModel }
      }),
      _vm._v(" "),
      _c("edit-image", { ref: "edit-image", on: { close: _vm.getModel } }),
      _vm._v(" "),
      _c("ImagePreview", { ref: "preview", attrs: { ratio: "1x1" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }